import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "canvas-panel", staticStyle: { left: "0" } },
    [
      _vm.showOverlay
        ? _c(
            VOverlay,
            [
              _c(
                VCol,
                { attrs: { align: "center" } },
                [
                  _c("div", [_vm._v(_vm._s(_vm.showOverlay))]),
                  _c(
                    VProgressCircular,
                    { attrs: { indeterminate: "", size: "64" } },
                    [
                      _c(VBtn, {
                        attrs: { text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.closeOverlay()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        VLayout,
        [
          _c(
            "modal",
            { attrs: { name: "uml-modal", height: "80%", width: "80%" } },
            [_c("class-modeler")],
            1
          ),
        ],
        1
      ),
      _c(
        VSnackbar,
        {
          attrs: {
            color: _vm.snackbar.color,
            "multi-line": _vm.snackbar.mode === "multi-line",
            timeout: _vm.snackbar.timeout,
            vertical: _vm.snackbar.mode === "vertical",
            top: "",
            centered: "",
          },
          model: {
            value: _vm.snackbar.show,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "show", $$v)
            },
            expression: "snackbar.show",
          },
        },
        [
          _vm._v("\n        " + _vm._s(_vm.snackbar.text) + "\n        "),
          _c(
            VBtn,
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { small: "", dark: "" },
              on: {
                click: function ($event) {
                  _vm.snackbar.show = false
                },
              },
            },
            [_vm._v("\n            Close\n        ")]
          ),
        ],
        1
      ),
      _c(
        VAlert,
        {
          staticStyle: {
            "align-self": "center",
            position: "absolute",
            "z-index": "999",
            "margin-left": "35%",
          },
          attrs: {
            dense: "",
            elevation: "6",
            "colored-border": "",
            value: _vm.alertInfo.show,
            border: _vm.alertInfo.border,
            type: _vm.alertInfo.type,
            "max-width": _vm.alertInfo.maxWidth,
            color: _vm.alertInfo.color,
            width: "100%",
          },
        },
        [
          _c("div", { staticStyle: { color: "black" } }, [
            _vm._v(" " + _vm._s(_vm.alertInfo.text)),
          ]),
          _vm.alertInfo.link
            ? _c(
                "a",
                { attrs: { target: "_blank", href: _vm.alertInfo.link } },
                [_vm._v("Github Persenal Access Token 발행 방법")]
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _vm.alertInfo.submit
                ? _c(
                    VBtn,
                    {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { small: "", color: "green", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.alertSubmit(_vm.alertInfo.fnNum)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.alertInfo.submit) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                VBtn,
                {
                  attrs: { small: "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.alertClose(_vm.alertInfo.fnNum)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("word.close")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        VLayout,
        { attrs: { right: "" } },
        [
          _vm.value
            ? _c(
                "opengraph",
                {
                  ref: "opengraph",
                  attrs: {
                    width: 100000,
                    height: 100000,
                    sliderLocationScale: _vm.sliderLocationScale,
                    "focus-canvas-on-select": "",
                    wheelScalable: "",
                    labelEditable: true,
                    dragPageMovable: _vm.dragPageMovable,
                    enableContextmenu: false,
                    enableRootContextmenu: false,
                    enableHotkeyCtrlC: false,
                    enableHotkeyCtrlV: false,
                    enableHotkeyDelete: false,
                    enableHotkeyCtrlZ: false,
                    enableHotkeyCtrlD: false,
                    enableHotkeyCtrlG: false,
                    slider: true,
                    movable: !_vm.isReadOnlyModel,
                    resizable: !_vm.isReadOnlyModel,
                    selectable: true,
                    connectable: !_vm.isReadOnlyModel,
                    autoSliderUpdate: true,
                    imageBase: _vm.imageBase,
                  },
                  on: {
                    "update:sliderLocationScale": function ($event) {
                      _vm.sliderLocationScale = $event
                    },
                    connectShape: _vm.onConnectShape,
                    canvasReady: _vm.bindEvents,
                  },
                },
                [
                  _vm._l(Object.keys(_vm.value.elements), function (elementId) {
                    return _vm.value.elements &&
                      typeof _vm.value.elements == "object"
                      ? _c(
                          "div",
                          { key: elementId },
                          [
                            elementId && _vm.value.elements[elementId]
                              ? _c(
                                  _vm.getComponentByClassName(
                                    _vm.value.elements[elementId]._type
                                  ),
                                  {
                                    ref: elementId,
                                    refInFor: true,
                                    tag: "component",
                                    attrs: {
                                      value: _vm.value.elements[elementId],
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.value.elements,
                                          elementId,
                                          $event
                                        )
                                      },
                                    },
                                  }
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm._l(
                    Object.keys(_vm.value.relations),
                    function (relationId) {
                      return _vm.value.relations &&
                        typeof _vm.value.relations == "object"
                        ? _c(
                            "div",
                            { key: relationId },
                            [
                              relationId && _vm.value.relations[relationId]
                                ? _c(
                                    _vm.getComponentByClassName(
                                      _vm.value.relations[relationId]._type
                                    ),
                                    {
                                      ref: relationId,
                                      refInFor: true,
                                      tag: "component",
                                      attrs: {
                                        value: _vm.value.relations[relationId],
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.value.relations,
                                            relationId,
                                            $event
                                          )
                                        },
                                      },
                                    }
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e()
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            VLayout,
            { attrs: { row: "" } },
            [
              !_vm.isReadOnlyModel
                ? _c(
                    VFlex,
                    [
                      _c(
                        VRow,
                        {
                          staticClass: "gs-modeling-undo-redo",
                          staticStyle: { "margin-top": "24px" },
                        },
                        [
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              staticClass:
                                                "gs-model-z-index-2 gs-undo-opacity-hover",
                                              attrs: {
                                                disabled: _vm.isUndoDisabled,
                                                text: "",
                                                small: "",
                                                right: "",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.undo()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              VIcon,
                                              { attrs: { medium: "" } },
                                              [_vm._v("mdi-undo")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                695928026
                              ),
                            },
                            [_c("span", [_vm._v("Undo")])]
                          ),
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              staticClass:
                                                "gs-model-z-index-2 gs-undo-opacity-hover",
                                              attrs: {
                                                disabled: _vm.isRedoDisabled,
                                                text: "",
                                                small: "",
                                                right: "",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.redo()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              VIcon,
                                              { attrs: { medium: "" } },
                                              [_vm._v("mdi-redo")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1134616950
                              ),
                            },
                            [_c("span", [_vm._v("Redo")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "cjm-mobile-btn" },
                [
                  _c(
                    VSpeedDial,
                    {
                      staticStyle: {
                        position: "absolute",
                        bottom: "50px",
                        right: "50px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    color: "blue darken-2",
                                    dark: "",
                                    fab: "",
                                    small: "",
                                  },
                                  model: {
                                    value: _vm.fab,
                                    callback: function ($$v) {
                                      _vm.fab = $$v
                                    },
                                    expression: "fab",
                                  },
                                },
                                [
                                  _vm.fab
                                    ? _c(VIcon, [
                                        _vm._v(
                                          "\n                                mdi-playlist-remove\n                            "
                                        ),
                                      ])
                                    : _c(VIcon, [
                                        _vm._v(
                                          "\n                                mdi-playlist-check\n                            "
                                        ),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.fab,
                        callback: function ($$v) {
                          _vm.fab = $$v
                        },
                        expression: "fab",
                      },
                    },
                    [
                      _c(
                        VRow,
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            id: "mobile-action-btn",
                            justify: "end",
                            align: "start",
                          },
                        },
                        [
                          _vm._t("saveButton", function () {
                            return [
                              _c(
                                VMenu,
                                {
                                  attrs: {
                                    "open-on-hover": "",
                                    "offset-y": "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _vm.isReadOnlyModel
                                            ? _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: _vm.joinRequestedText
                                                      .show
                                                      ? "primary"
                                                      : "success",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.requestInviteUser()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.joinRequestedText.show
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(VIcon, [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.icon.join
                                                              )
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.joinRequestedText
                                                          .text
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.isReadOnlyModel
                                            ? _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary",
                                                    dark: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.saveComposition(
                                                        "fork"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      _vm._s(_vm.icon.fork)
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    "\n                                        FORK\n                                    "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                VBtn,
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "primary",
                                                      dark: "",
                                                      small: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.saveComposition(
                                                          "save"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      _vm._s(_vm.icon.save)
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    "\n                                        SAVE\n                                    "
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    VList,
                                    _vm._l(
                                      _vm.saveItems,
                                      function (item, index) {
                                        return _c(
                                          VListItem,
                                          {
                                            key: index,
                                            on: {
                                              click: function ($event) {
                                                return _vm.functionSelect(
                                                  item.title,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(VListItemTitle, [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          }),
                          _vm._t("shareButton", function () {
                            return [
                              _vm.isOwnModel &&
                              _vm.isServerModel &&
                              !_vm.isReadOnlyModel
                                ? _c(
                                    VMenu,
                                    {
                                      staticClass: "pa-2",
                                      attrs: {
                                        "offset-y": "",
                                        "open-on-hover": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      VBtn,
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            dark: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openInviteUsers()
                                                            },
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.icon.share
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          "\n                                            SHARE\n                                            "
                                                        ),
                                                        _vm.requestCount
                                                          ? _c(
                                                              VAvatar,
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "2px",
                                                                },
                                                                attrs: {
                                                                  size: "25",
                                                                  color: "red",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.requestCount
                                                                    ) +
                                                                    "\n                                            "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2142891974
                                      ),
                                    },
                                    [
                                      _c(
                                        VList,
                                        _vm._l(
                                          _vm.shareItems,
                                          function (item, index) {
                                            return _c(
                                              VListItem,
                                              {
                                                key: index,
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.functionSelect(
                                                      item.title,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(_vm._s(item.title)),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          }),
                          _vm._t("versionButton", function () {
                            return [
                              _vm.isServerModel
                                ? _c(VMenu, {
                                    staticClass: "pa-2",
                                    attrs: {
                                      text: "",
                                      "open-on-hover": "",
                                      "offset-y": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      attrs: {
                                                        dark: "",
                                                        small: "",
                                                        disabled:
                                                          _vm.disableBtn,
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showReplay()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(VIcon, [
                                                        _vm._v("mdi-restart"),
                                                      ]),
                                                      _vm._v(
                                                        "\n                                            Replay\n                                        "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3135521774
                                    ),
                                  })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._t("top", function () {
                return [
                  _c(
                    VFlex,
                    { staticStyle: { justify: "end", align: "start" } },
                    [
                      _c(
                        VRow,
                        {
                          staticClass: "gs-model-z-index-1",
                          staticStyle: {
                            position: "absolute",
                            left: "50%",
                            transform: "translate(-50%, 0%)",
                            "margin-top": "0px",
                          },
                        },
                        [
                          _c(
                            VRow,
                            {
                              staticStyle: {
                                "margin-right": "5px",
                                "margin-top": "15px",
                                width: "300px",
                              },
                              attrs: { justify: "end", align: "start" },
                            },
                            [
                              _vm._t("projectName", function () {
                                return [
                                  _c(
                                    VCol,
                                    {
                                      attrs: {
                                        id: "project-name",
                                        align: "start",
                                        justify: "end",
                                        cols: "10",
                                        md: "10",
                                        sm: "10",
                                      },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          disabled:
                                            _vm.isReadOnlyModel ||
                                            (_vm.fullPath &&
                                              _vm.fullPath.includes("replay")),
                                          color: _vm.projectNameColor,
                                          "error-messages": _vm.projectNameHint,
                                          label: "Project Name",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.unselectedAll.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.projectName,
                                          callback: function ($$v) {
                                            _vm.projectName = $$v
                                          },
                                          expression: "projectName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "cjm-btn" },
                            [
                              _c(
                                VRow,
                                {
                                  staticStyle: {
                                    "margin-right": "15px",
                                    "margin-top": "15px",
                                  },
                                  attrs: {
                                    id: "action-btn",
                                    justify: "end",
                                    align: "start",
                                  },
                                },
                                [
                                  _vm._t("versionButton", function () {
                                    return [
                                      _vm.isServerModel
                                        ? _c(VMenu, {
                                            staticClass: "pa-2",
                                            attrs: {
                                              "open-on-hover": "",
                                              "offset-y": "",
                                              left: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "5px",
                                                                "margin-top":
                                                                  "15px",
                                                              },
                                                              attrs: {
                                                                text: "",
                                                                disabled:
                                                                  _vm.disableBtn,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showReplay()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(VIcon, [
                                                                _vm._v(
                                                                  "mdi-restart"
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                "\n                                                    Replay\n                                                "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3117959332
                                            ),
                                          })
                                        : _vm._e(),
                                    ]
                                  }),
                                  _vm._t("saveButton", function () {
                                    return [
                                      _c(
                                        VMenu,
                                        {
                                          staticClass: "pa-2",
                                          attrs: {
                                            "open-on-hover": "",
                                            "offset-y": "",
                                            left: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _vm.isReadOnlyModel
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "5px",
                                                                "margin-top":
                                                                  "15px",
                                                              },
                                                              attrs: {
                                                                text: "",
                                                                color:
                                                                  "primary",
                                                                disabled:
                                                                  _vm.disableBtn,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveComposition(
                                                                      "fork"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(VIcon, [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.icon
                                                                      .fork
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                "\n                                                    FORK\n                                                "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "5px",
                                                                "margin-top":
                                                                  "15px",
                                                              },
                                                              attrs: {
                                                                color: _vm
                                                                  .joinRequestedText
                                                                  .show
                                                                  ? "primary"
                                                                  : "success",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.requestInviteUser()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm
                                                                .joinRequestedText
                                                                .show
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .icon
                                                                                .join
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .joinRequestedText
                                                                      .text
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        [
                                                          _vm.isOwnModel
                                                            ? _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "5px",
                                                                        "margin-top":
                                                                          "15px",
                                                                      },
                                                                    attrs: {
                                                                      text: "",
                                                                      color:
                                                                        "primary",
                                                                      disabled:
                                                                        _vm.disableBtn,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.saveComposition(
                                                                            "save"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .save
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    "\n                                                    SAVE\n                                                "
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                VBtn,
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "5px",
                                                                    "margin-top":
                                                                      "15px",
                                                                  },
                                                                  attrs: {
                                                                    text: "",
                                                                    color:
                                                                      "primary",
                                                                    disabled:
                                                                      _vm.disableBtn,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.saveComposition(
                                                                          "fork"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .fork
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    "\n                                                    FORK\n                                                "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            VList,
                                            _vm._l(
                                              _vm.saveItems,
                                              function (item, index) {
                                                return _c(
                                                  VListItem,
                                                  {
                                                    key: index,
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.functionSelect(
                                                          item.title,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(VListItemTitle, [
                                                      _vm._v(
                                                        _vm._s(item.title)
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                  _vm._t("shareButton", function () {
                                    return [
                                      _vm.isOwnModel &&
                                      _vm.isServerModel &&
                                      !_vm.isReadOnlyModel
                                        ? _c(
                                            VMenu,
                                            {
                                              attrs: {
                                                "offset-y": "",
                                                "open-on-hover": "",
                                                left: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "5px",
                                                                    "margin-top":
                                                                      "15px",
                                                                  },
                                                                  attrs: {
                                                                    text: "",
                                                                    disabled:
                                                                      _vm.disableBtn,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openInviteUsers()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.icon
                                                                        .share
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm._v(
                                                                  "\n                                                    SHARE\n                                                    "
                                                                ),
                                                                _vm.requestCount
                                                                  ? _c(
                                                                      VAvatar,
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-left":
                                                                              "2px",
                                                                          },
                                                                        attrs: {
                                                                          size: "25",
                                                                          color:
                                                                            "red",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.requestCount
                                                                            ) +
                                                                            "\n                                                    "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                118165446
                                              ),
                                            },
                                            [
                                              _c(
                                                VList,
                                                _vm._l(
                                                  _vm.shareItems,
                                                  function (item, index) {
                                                    return _c(
                                                      VListItem,
                                                      {
                                                        key: index,
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.functionSelect(
                                                              item.title,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VListItemTitle,
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.title)
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
              _c(
                VFabTransition,
                [
                  !_vm.rtcLogin &&
                  _vm.isServerModel &&
                  _vm.information &&
                  _vm.information.permissions
                    ? _c(
                        VBtn,
                        {
                          staticStyle: { margin: "0 5px 40px 0" },
                          attrs: {
                            color: "blue",
                            dark: "",
                            fab: "",
                            small: "",
                            absolute: "",
                            bottom: "",
                            right: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onJoin()
                            },
                          },
                        },
                        [_c(VIcon, [_vm._v("mdi-camera")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            VCard,
            {
              staticClass: "tools",
              staticStyle: { top: "100px", "text-align": "center" },
            },
            [
              _c(
                "span",
                {
                  staticClass: "bpmn-icon-hand-tool",
                  class: {
                    icons: !_vm.dragPageMovable,
                    hands: _vm.dragPageMovable,
                  },
                  attrs: { _width: "30", _height: "30" },
                  on: { click: _vm.toggleGrip },
                },
                [
                  _c(VTooltip, { attrs: { "md-direction": "right" } }, [
                    _vm._v("Hands"),
                  ]),
                ],
                1
              ),
              _vm._l(_vm.elementTypes, function (item, key) {
                return _c(
                  VTooltip,
                  {
                    key: key,
                    attrs: { right: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "draggable",
                                  attrs: {
                                    align: "center",
                                    _component: item.component,
                                    _width: item.width,
                                    _height: item.height,
                                  },
                                },
                                [
                                  !_vm.isReadOnlyModel
                                    ? _c(
                                        "img",
                                        _vm._g(
                                          {
                                            attrs: {
                                              height: "30px",
                                              width: "30px",
                                              src: item.src,
                                            },
                                          },
                                          on
                                        )
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v(_vm._s(item.label))])]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.showParticipantPanel
        ? _c("ParticipantPanel", { attrs: { lists: _vm.participantLists } })
        : _vm._e(),
      _c("model-canvas-share-dialog", {
        attrs: {
          showDialog: _vm.inviteDialog,
          checkPublic: _vm.showPublicModel,
          canvas: _vm.canvas,
          canvasComponentName: "customer-journey-map",
        },
        on: {
          all: _vm.invitePublic,
          apply: _vm.applyInviteUsers,
          close: _vm.closeInviteUsers,
          add: _vm.addInviteUser,
          remove: _vm.removeInviteUser,
        },
        model: {
          value: _vm.inviteLists,
          callback: function ($$v) {
            _vm.inviteLists = $$v
          },
          expression: "inviteLists",
        },
      }),
      _c("model-storage-dialog", {
        attrs: {
          condition: _vm.storageCondition,
          showDialog: _vm.showStorageDialog,
        },
        on: {
          save: _vm.saveModel,
          fork: _vm.forkModel,
          backup: _vm.backupModel,
          close: _vm.storageDialogCancel,
        },
      }),
      _c("dialog-purchase-item", {
        attrs: { "purchase-item-info": _vm.purchaseItemDialogInfo },
        on: {
          result: _vm.purchaseItemDialogSubmit,
          close: _vm.purchaseItemDialogClose,
        },
        model: {
          value: _vm.purchaseItemDialog,
          callback: function ($$v) {
            _vm.purchaseItemDialog = $$v
          },
          expression: "purchaseItemDialog",
        },
      }),
      _c(
        VDialog,
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.forkAlertDialog,
            callback: function ($$v) {
              _vm.forkAlertDialog = $$v
            },
            expression: "forkAlertDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                { staticClass: "headline" },
                [
                  _vm._v("Fork\n                "),
                  _c(VIcon, [_vm._v(_vm._s(_vm.icon.fork))]),
                ],
                1
              ),
              _c(VCardText, [
                _vm._v(
                  "\n                권한이 없어서 수정 할 수 없습니다. Fork를 하여 사용해 주세요.\n           "
                ),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.saveComposition("fork")
                        },
                      },
                    },
                    [_vm._v("\n                    Fork\n                ")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "red darken-1", text: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.forkAlertDialog = false
                        },
                      },
                    },
                    [_vm._v("\n                    Close\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.projectId
        ? _c("GeneratorUI", {
            ref: "generatorUI",
            attrs: {
              projectId: _vm.projectId,
              modelValue: _vm.value,
              defaultInputData: _vm.defaultGeneratorUiInputData,
            },
            on: {
              createModel: _vm.createModel,
              clearModelValue: _vm.clearModelValue,
              modificateModel: _vm.modificateModel,
            },
          })
        : _vm._e(),
      _c("modeler-image-generator", { ref: "modeler-image-generator" }),
      _c(
        "hsc-window-style-metal",
        [
          _c(
            "hsc-window",
            {
              staticStyle: {
                color: "rgb(0, 0, 0)",
                "box-shadow": "rgba(0, 0, 0, 0.5) 0px 4pt 8pt",
                background:
                  "linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255))",
                "z-index": "0",
                overflow: "visible",
                width: "404px",
                height: "154.2px",
              },
              attrs: {
                title: "User Camera",
                closeButton: true,
                isOpen: _vm.webRtcDialog,
                resizable: true,
                isScrollable: true,
                minWidth: 400,
                minHeight: 110,
                maxWidth: 800,
                maxHeight: 110,
                positionHint: "5 / -10",
              },
              on: {
                "update:isOpen": function ($event) {
                  _vm.webRtcDialog = $event
                },
                "update:is-open": function ($event) {
                  _vm.webRtcDialog = $event
                },
              },
            },
            [
              _c(
                VLayout,
                [
                  _c(
                    VCol,
                    [
                      _c(
                        VRow,
                        {
                          staticStyle: {
                            "margin-left": "2px",
                            "margin-bottom": "2px",
                          },
                        },
                        [
                          _c("vue-webrtc", {
                            ref: "webrtc",
                            staticClass: "video-list",
                            attrs: {
                              width: "100%",
                              height: "160px",
                              cameraHeight: "50px",
                              enableAudio: "",
                              roomId: _vm.rtcRoomId,
                            },
                            on: { error: _vm.onError },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.filteredMouseEventHandlers, function (otherMouseEvent, email) {
        return _c(
          "div",
          { key: email },
          [
            _c("MouseCursorComponent", {
              attrs: { mouseEvent: otherMouseEvent, email: email },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }