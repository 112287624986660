var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("edge-element", {
        attrs: {
          selectable: !_vm.movingElement,
          movable: !_vm.canvas.isReadOnlyModel && !_vm.movingElement,
          deletable: !_vm.canvas.isReadOnlyModel && _vm.isEditElement,
          connectable: !_vm.canvas.isReadOnlyModel,
          id: _vm.value.relationView.id,
          vertices: _vm.vertices,
          label: _vm.value.name,
          _style: {
            "arrow-start": "none",
            "arrow-end": "none",
            "stroke-width": _vm.value.size,
            stroke: _vm.value.color,
            "stroke-dasharray": _vm.value.dashStyle,
          },
        },
        on: {
          "update:id": function ($event) {
            return _vm.$set(_vm.value.relationView, "id", $event)
          },
          "update:vertices": function ($event) {
            _vm.vertices = $event
          },
          selectShape: _vm.selectedActivity,
          deSelectShape: _vm.deSelectedActivity,
          dblclick: _vm.openPanel,
          customMoveActionExist: _vm.canvas.isCustomMoveExist,
          customRelationMoveAction: _vm.delayedRelationMove,
          removeShape: _vm.onRemoveShape,
        },
      }),
      _vm.propertyPanel
        ? _c("cjm-model-panel", {
            attrs: { isReadOnly: !_vm.isEditElement },
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }